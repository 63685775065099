import { AuthState } from '../config/types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PASSWORD_CHANGING,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESETTING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  LOGOUT,
  AuthDataTypes,
  VERIFYING_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
} from '../config/ActionTypes';

export const initialState: AuthState = {
  loggingIn: false,
  loginSuccess: false,
  user: null,
  logInErrorMessage: null,
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordErrorMessage: null,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordErrorMessage: null,
  verifyingEmail: false,
  verifyEmailSuccess: false,
  verifyEmailErrorMessage: null,
};

export function authReducer(state = initialState, action: AuthDataTypes): AuthState {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loginSuccess: false,
        user: null,
        logInErrorMessage: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loginSuccess: true,
        user: action.email,
        logInErrorMessage: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loginSuccess: false,
        user: null,
        logInErrorMessage: action.error,
      };

    case PASSWORD_CHANGING:
      return {
        ...state,
        changingPassword: true,
        changePasswordSuccess: false,
        changePasswordErrorMessage: null,
      };

    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changePasswordErrorMessage: null,
      };

    case PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changePasswordErrorMessage: action.error,
      };

    case PASSWORD_RESETTING:
      return {
        ...state,
        resettingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: null,
      };

    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordErrorMessage: null,
      };

    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordErrorMessage: action.error,
      };

    case VERIFYING_EMAIL:
      return {
        ...state,
        verifyingEmail: true,
        verifyEmailSuccess: false,
        verifyEmailErrorMessage: null,
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyingEmail: false,
        verifyEmailSuccess: true,
        verifyEmailErrorMessage: null,
      };

    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifyingEmail: false,
        verifyEmailSuccess: false,
        verifyEmailErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...state,
        loggingIn: false,
        loginSuccess: false,
        logInErrorMessage: null,
      };

    default:
      return state;
  }
}
