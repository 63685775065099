import {
  Issuer,
  SupportedIssuer,
  MoneyboxResponse,
  TransactionsFilters,
  TransactionsResponse,
  MoneyboxFilters,
  MoneyboxTotalsResponse,
  Order,
  SortMoneyboxValues,
  SortTransactionValues,
  MoneyboxSort,
} from './types';

//#region Auth actions

export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface LoggingInAction {
  type: typeof LOGGING_IN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  email: string;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction;

export const PASSWORD_CHANGING = 'PASSWORD_CHANGING';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';

export interface PasswordChanging {
  type: typeof PASSWORD_CHANGING;
}
export interface PasswordChangedSuccess {
  type: typeof PASSWORD_CHANGE_SUCCESS;
}
export interface PasswordChangedError {
  type: typeof PASSWORD_CHANGE_ERROR;
  error: string;
}

export type PasswordChangeTypes = PasswordChanging | PasswordChangedSuccess | PasswordChangedError;

export const PASSWORD_RESETTING = 'PASSWORD_RESETTING';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export interface PasswordResetting {
  type: typeof PASSWORD_RESETTING;
}
export interface PasswordResetSuccess {
  type: typeof PASSWORD_RESET_SUCCESS;
}
export interface PasswordResetError {
  type: typeof PASSWORD_RESET_ERROR;
  error: string;
}

export type PasswordResetTypes = PasswordResetting | PasswordResetSuccess | PasswordResetError;

export const VERIFYING_EMAIL = 'VERIFYING_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';

export interface VerifyingEmail {
  type: typeof VERIFYING_EMAIL;
}
export interface VerifyEmailSuccess {
  type: typeof VERIFY_EMAIL_SUCCESS;
}
export interface VerifyEmailError {
  type: typeof VERIFY_EMAIL_ERROR;
  error: string;
}

export type VerifyEmailTypes = VerifyingEmail | VerifyEmailSuccess | VerifyEmailError;

export const LOGOUT = 'LOGOUT';

export interface Logout {
  type: typeof LOGOUT;
}

export type LogoutTypes = Logout;

export type AuthDataTypes =
  | LoginActionTypes
  | PasswordChangeTypes
  | PasswordResetTypes
  | VerifyEmailTypes
  | LogoutTypes;

//#endregion

//#region Moneyboxes actions

export const MONEYBOXES_FETCHING = 'MONEYBOXES_FETCHING';
export const MONEYBOXES_FETCH_SUCCESS = 'MONEYBOXES_FETCH_SUCCESS';
export const MONEYBOXES_FETCH_ERROR = 'MONEYBOXES_FETCH_ERROR';

export interface MoneyboxesFetching {
  type: typeof MONEYBOXES_FETCHING;
  filters: MoneyboxFilters;
  sort: MoneyboxSort | null;
  reset: boolean;
}

export interface MoneyboxesFetchSuccess {
  type: typeof MONEYBOXES_FETCH_SUCCESS;
  moneyboxResponse: MoneyboxResponse;
}

export interface MoneyboxesFetchError {
  type: typeof MONEYBOXES_FETCH_ERROR;
  error: string;
}

export type MoneyboxesFetchTypes = MoneyboxesFetching | MoneyboxesFetchSuccess | MoneyboxesFetchError;

export const MONEYBOXES_DISABLING = 'MONEYBOXES_DISABLING';
export const MONEYBOXES_DISABLE_SUCCESS = 'MONEYBOXES_DISABLE_SUCCESS';
export const MONEYBOXES_DISABLE_ERROR = 'MONEYBOXES_DISABLE_ERROR';

export interface MoneyboxDisabling {
  type: typeof MONEYBOXES_DISABLING;
}
export interface MoneyboxDisableSuccess {
  type: typeof MONEYBOXES_DISABLE_SUCCESS;
  id: string;
}
export interface MoneyboxDisableError {
  type: typeof MONEYBOXES_DISABLE_ERROR;
  error: string;
}

export type MoneyboxDisableTypes = MoneyboxDisabling | MoneyboxDisableSuccess | MoneyboxDisableError;

export const MONEYBOXES_FETCHING_ACTIVE = 'MONEYBOXES_FETCHING_ACTIVE';
export const MONEYBOXES_FETCH_ACTIVE_SUCCESS = 'MONEYBOXES_FETCH_ACTIVE_SUCCESS';
export const MONEYBOXES_FETCH_ACTIVE_ERROR = 'MONEYBOXES_FETCH_ACTIVE_ERROR';

export interface MoneyboxesFetchingActive {
  type: typeof MONEYBOXES_FETCHING_ACTIVE;
}

export interface MoneyboxesFetchActiveSuccess {
  type: typeof MONEYBOXES_FETCH_ACTIVE_SUCCESS;
  total: number;
}

export interface MoneyboxesFetchActiveError {
  type: typeof MONEYBOXES_FETCH_ACTIVE_ERROR;
  error: string;
}

export type MoneyboxesFetchActiveTypes =
  | MoneyboxesFetchingActive
  | MoneyboxesFetchActiveSuccess
  | MoneyboxesFetchActiveError;

export const MONEYBOXES_FETCHING_TOTALS = 'MONEYBOXES_FETCHING_TOTALS';
export const MONEYBOXES_FETCH_TOTALS_SUCCESS = 'MONEYBOXES_FETCH_TOTALS_SUCCESS';
export const MONEYBOXES_FETCH_TOTALS_ERROR = 'MONEYBOXES_FETCH_TOTALS_ERROR';

export interface MoneyboxesFetchingTotals {
  type: typeof MONEYBOXES_FETCHING_TOTALS;
}

export interface MoneyboxesFetchTotalsSuccess {
  type: typeof MONEYBOXES_FETCH_TOTALS_SUCCESS;
  response: MoneyboxTotalsResponse;
}

export interface MoneyboxesFetchTotalsError {
  type: typeof MONEYBOXES_FETCH_TOTALS_ERROR;
  error: string;
}

export type MoneyboxesFetchTotalsTypes =
  | MoneyboxesFetchingTotals
  | MoneyboxesFetchTotalsSuccess
  | MoneyboxesFetchTotalsError;

export const MONEYBOXES_SORT = 'MONEYBOX_SORT';
export interface MoneyboxesSorting {
  type: typeof MONEYBOXES_SORT;
  sortBy: keyof SortMoneyboxValues;
  order: Order;
}

export type MoneyboxesSortTypes = MoneyboxesSorting;

export type MoneyboxesDataTypes =
  | MoneyboxesFetchTypes
  | MoneyboxDisableTypes
  | MoneyboxesFetchActiveTypes
  | MoneyboxesFetchTotalsTypes
  | MoneyboxesSortTypes;

//#endregion

//#region Issuers actions

export const LOADING_ISSUERS = 'LOADING_ISSUERS';
export const ADD_ISSUERS = 'ADD_ISSUERS';
export const ISSUERS_ERROR = 'ISSUERS_ERROR';

export interface LoadingIssuers {
  type: typeof LOADING_ISSUERS;
}

export interface AddIssuers {
  type: typeof ADD_ISSUERS;
  issuers: Issuer[];
}

export interface IssuersError {
  type: typeof ISSUERS_ERROR;
  error: string;
}

export type FetchIssuersTypes = LoadingIssuers | AddIssuers | IssuersError;

export const CREATING_ISSUER = 'CREATING_ISSUER';
export const ADD_ISSUER = 'ADD_ISSUER';
export const CREATE_ISSUER_ERROR = 'CREATE_ISSUER_ERROR';

export interface CreatingIssuer {
  type: typeof CREATING_ISSUER;
}

export interface AddIssuer {
  type: typeof ADD_ISSUER;
  issuer: Issuer;
}

export interface CreateIssuerError {
  type: typeof CREATE_ISSUER_ERROR;
  error: string;
}

export type CreateIssuerTypes = CreatingIssuer | AddIssuer | CreateIssuerError;

export const DELETING_ISSUER = 'DELETING_ISSUER';
export const DELETE_ISSUER = 'DELETE_ISSUER';
export const DELETE_ISSUER_ERROR = 'DELETE_ISSUER_ERROR';

export interface DeletingIssuer {
  type: typeof DELETING_ISSUER;
}

export interface DeleteIssuer {
  type: typeof DELETE_ISSUER;
  issuerId: number;
}

export interface DeleteIssuerError {
  type: typeof DELETE_ISSUER_ERROR;
  error: string;
}

export type DeleteIssuerTypes = DeletingIssuer | DeleteIssuer | DeleteIssuerError;

export const LOADING_SUPPORTED_ISSUERS = 'LOADING_SUPPORTED_ISSUERS';
export const ADD_SUPPORTED_ISSUERS = 'ADD_SUPPORTED_ISSUERS';
export const SUPPORTED_ISSUERS_ERROR = 'SUPPORTED_ISSUERS_ERROR';

export interface LoadingSupportedIssuers {
  type: typeof LOADING_SUPPORTED_ISSUERS;
}

export interface AddSupportedIssuers {
  type: typeof ADD_SUPPORTED_ISSUERS;
  issuers: SupportedIssuer[];
}

export interface SupportedIssuersError {
  type: typeof SUPPORTED_ISSUERS_ERROR;
  error: string;
}

export type FetchSupportedIssuersTypes =
  | LoadingSupportedIssuers
  | AddSupportedIssuers
  | SupportedIssuersError;

export type IssuersDataTypes =
  | FetchIssuersTypes
  | CreateIssuerTypes
  | DeleteIssuerTypes
  | FetchSupportedIssuersTypes;

//#endregion

//#region Transactions actions

export const LOADING_TRANSACTIONS = 'LOADING_TRANSACTIONS';
export const ADD_TRANSACTIONS = 'ADD_TRANSACTIONS';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';

export interface LoadingTransactions {
  type: typeof LOADING_TRANSACTIONS;
  filters: TransactionsFilters;
  reset: boolean;
}

export interface AddTransactions {
  type: typeof ADD_TRANSACTIONS;
  transactionsResponse: TransactionsResponse;
}

export interface TransactionsError {
  type: typeof TRANSACTIONS_ERROR;
  error: string;
}

export type FetchTransactionsTypes =
  | LoadingTransactions
  | AddTransactions
  | TransactionsError
  | TransactionsSort;

export const TRANSACTIONS_SORT = 'TRANSACTIONS_SORT';

export interface TransactionsSort {
  type: typeof TRANSACTIONS_SORT;
  sortBy: keyof SortTransactionValues;
  order: Order;
}

export type TransactionsDataTypes = FetchTransactionsTypes;

//#endregion

export type AppActions = AuthDataTypes | MoneyboxesDataTypes | IssuersDataTypes | TransactionsDataTypes;
