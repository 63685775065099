import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffc130",
    },
    secondary: {
      main: "#327cbc",
    },
    error: {
      main: "#f11930",
    },
  },
  typography: {
    fontFamily: `"Roboto Condensed", sans-serif`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 6,
        textTransform: "none",
        padding: "8px 16px",
        fontSize: 16,
      },
    },
  },
});
