import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field, Form, Formik } from "formik";
import { FormSelectField } from "../../forms/FormSelectField";
import { CustomSnackbar } from "../../common/CustomSnackbar";
import {
  CreateIssuerRequest,
  DeleteIssuerRequest,
  Issuer,
  RootState,
  SupportedIssuer,
} from "../../../store/config/types";
import { FormTextField } from "../../forms/FormTextField";
import { AppActions } from "../../../store/config/ActionTypes";
import { ThunkDispatch } from "redux-thunk";
import {
  createIssuer,
  deleteIssuer,
} from "../../../store/action_creators/issuers.actions";
import { connect, ConnectedProps } from "react-redux";
import { DisableDialog } from "../../common/DisableDialog";

interface CommerceDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  supportedIssuers: SupportedIssuer[];
  selectedIssuer?: Issuer | null;
}

interface Values {
  issuerId: string;
  commerceNumber: string;
  commerceTerminalNumber: string;
}

interface Option {
  id: string;
  value: string;
}

const mapStateToProps = (state: RootState) => ({
  issuers: state.issuers,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  createIssuer: ({
    issuerId,
    commerceNumber,
    commerceTerminalNumber,
  }: CreateIssuerRequest) =>
    dispatch(
      createIssuer({ issuerId, commerceNumber, commerceTerminalNumber })
    ),
  deleteIssuer: ({ issuerId }: DeleteIssuerRequest) =>
    dispatch(deleteIssuer({ issuerId })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & CommerceDialogProps;

function CommerceDialog({
  issuers,
  createIssuer,
  deleteIssuer,
  open,
  setOpen,
  supportedIssuers,
  selectedIssuer,
}: PropsType) {
  const [creating, setCreating] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const submit = (values: Values) => {
    createIssuer({
      issuerId: Number(values.issuerId),
      commerceNumber: values.commerceNumber,
      commerceTerminalNumber: values.commerceTerminalNumber,
    });

    setCreating(true);
  };

  const closeSnack = () => {
    setCreating(false);
    setOpen(false);
  };

  const deleteAction = (issuerId: string) => {
    setDeleting(true);

    deleteIssuer({
      issuerId: Number(issuerId),
    });
  };

  const closeDeleteSnack = () => {
    setDeleting(false);

    if (issuers.deleteIssuerSuccess) {
      setOpen(false);
    }
  };

  const options: Option[] = supportedIssuers.map((s: SupportedIssuer) => {
    return {
      id: s.issuerId.toString(),
      value: s.issuer,
    };
  });

  console.log(issuers);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        classes={{ root: "commerce-dialog" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle classes={{ root: "commerce-dialog-title" }}>
          <span className="title">
            {selectedIssuer !== null
              ? "Detalles del comercio"
              : "Nuevo comercio"}
          </span>
          <CloseIcon
            color="secondary"
            className="blue-link"
            onClick={closeDialog}
          />
        </DialogTitle>
        <Formik
          initialValues={{
            issuerId: selectedIssuer
              ? selectedIssuer.issuerId.toString()
              : options[0].id,
            commerceNumber: selectedIssuer
              ? selectedIssuer.metadata.ProviderCommerceNumber
              : "",
            commerceTerminalNumber:
              selectedIssuer && selectedIssuer.metadata.TerminalNumber
                ? selectedIssuer.metadata.TerminalNumber
                : "",
          }}
          onSubmit={submit}
        >
          {(props) => {
            const theIssuer = supportedIssuers.filter(
              (i) => i.issuerId.toString() === props.values.issuerId
            )[0];

            return (
              <Form className="form">
                <DialogContent>
                  <Field
                    name="issuerId"
                    component={FormSelectField}
                    options={options}
                    disabled={selectedIssuer !== null}
                  />
                  {theIssuer.fields.map((field, index) => {
                    if (field.fieldType === 2049 && field.required) {
                      return (
                        <Field
                          key={index}
                          name="commerceNumber"
                          component={FormTextField}
                          type="text"
                          placeholder="Número de comercio"
                          disabled={selectedIssuer !== null}
                        />
                      );
                    }
                    if (field.fieldType === 2051 && field.required) {
                      return (
                        <Field
                          key={index}
                          name="commerceTerminalNumber"
                          component={FormTextField}
                          type="text"
                          placeholder="Número de terminal"
                          disabled={selectedIssuer !== null}
                        />
                      );
                    }
                    return null;
                  })}
                </DialogContent>
                <DialogActions classes={{ root: "commerce-dialog-actions" }}>
                  {selectedIssuer !== null ? (
                    <span
                      className="delete-link"
                      onClick={() => setDeleteOpen(true)}
                    >
                      Borrar comercio
                    </span>
                  ) : (
                    <span />
                  )}
                  {selectedIssuer !== null ? (
                    <span />
                  ) : (
                    <Button type="submit" color="secondary" variant="contained">
                      Crear comercio
                    </Button>
                  )}
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
        <CustomSnackbar
          open={
            creating &&
            (issuers.createIssuerSuccess ||
              issuers.createIssuerErrorMessage !== null)
          }
          message={
            issuers.createIssuerSuccess
              ? "Se creó el comercio correctamente"
              : "Ocurrió un error al crear el comercio"
          }
          handleClose={closeSnack}
          type={issuers.createIssuerSuccess ? 0 : 1}
        />
        <CustomSnackbar
          open={
            deleting &&
            (issuers.deleteIssuerSuccess ||
              issuers.deleteIssuerErrorMessage !== null)
          }
          message={
            issuers.deleteIssuerSuccess
              ? "Se borró el comercio correctamente"
              : "Ocurrió un error al borrar el comercio"
          }
          handleClose={closeDeleteSnack}
          type={issuers.deleteIssuerSuccess ? 0 : 1}
        />
      </Dialog>
      <DisableDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message="¿Estás seguro que deseas borrar el comercio?"
        deleteAction={() =>
          selectedIssuer && deleteAction(selectedIssuer.issuerId.toString())
        }
        deleteActionMessage="Si, borrar"
      />
    </>
  );
}

export default connector(CommerceDialog);
