import React, { Dispatch, SetStateAction } from "react";
import { Issuer, SupportedIssuer } from "../../../store/config/types";
import AddIcon from "@material-ui/icons/Add";

interface CommercesListProps {
  issuers: Issuer[];
  supportedIssuers: SupportedIssuer[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedIssuer: Dispatch<SetStateAction<Issuer | null>>;
}

function CommercesList({ issuers, supportedIssuers, setOpen, setSelectedIssuer }: CommercesListProps) {
  const createIssuer = () => {
    setSelectedIssuer(null);
    setOpen(true);
  };

  const selectIssuer = (issuer: Issuer) => {
    setSelectedIssuer(issuer);
    setOpen(true);
  };

  return (
    <div className="commerces-list">
      <div className="commerce">
        <div className="commerce-content add-commerce" onClick={createIssuer}>
          <AddIcon color="secondary" fontSize="large" />
          <span>Agregar comercio</span>
        </div>
      </div>
      {issuers.map((issuer: Issuer, index: number) => {
        const issuerData = supportedIssuers?.filter((s) => s.issuerId === issuer.issuerId)[0];

        return (
          <div className="commerce" key={index} onClick={() => selectIssuer(issuer)}>
            <div className="commerce-content">
              <img src={issuerData?.imageUrl} alt={issuerData?.issuer} />
              <span>{issuerData?.issuer}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CommercesList;
