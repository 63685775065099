import { LoginRequest, ChangePasswordRequest, ResetPasswordRequest } from '../store/config/types';
import { post, put } from './base.service';
import { endpoints } from './endpoints';

export const authService = {
  login,
  changePassword,
  resetPassword,
  verifyEmail,
};

async function login({ user }: LoginRequest): Promise<string | undefined> {
  return await post<string>(`${endpoints['authLogin']}`, user)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function changePassword({ oldPassword, newPassword }: ChangePasswordRequest): Promise<string> {
  return await put<string>(`${endpoints['authChangePassword']}`, { oldPassword, newPassword }, true)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function resetPassword(request: ResetPasswordRequest): Promise<string> {
  return await post<string>(`${endpoints['authResetPassword']}`, request)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function verifyEmail(email: string): Promise<string> {
  return await put<string>(`${endpoints['authVerifyEmail']}`, { email }, true)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
