import {
  MoneyboxResponse,
  FetchMoneyboxesRequest,
  MoneyboxFilters,
  MoneyboxTotalsResponse,
  Order,
  SortMoneyboxValues,
  MoneyboxSort,
} from "../config/types";
import { Dispatch } from "redux";
import {
  MONEYBOXES_FETCHING,
  MONEYBOXES_FETCH_SUCCESS,
  MONEYBOXES_FETCH_ERROR,
  MoneyboxesFetchTypes,
  MONEYBOXES_DISABLING,
  MONEYBOXES_DISABLE_SUCCESS,
  MONEYBOXES_DISABLE_ERROR,
  MoneyboxDisableTypes,
  MONEYBOXES_FETCHING_ACTIVE,
  MONEYBOXES_FETCH_ACTIVE_SUCCESS,
  MONEYBOXES_FETCH_ACTIVE_ERROR,
  MoneyboxesFetchActiveTypes,
  MONEYBOXES_FETCHING_TOTALS,
  MONEYBOXES_FETCH_TOTALS_SUCCESS,
  MONEYBOXES_FETCH_TOTALS_ERROR,
  MoneyboxesFetchTotalsTypes,
  MONEYBOXES_SORT,
  MoneyboxesSorting,
} from "../config/ActionTypes";
import { moneyboxesService } from "../../services/moneyboxes.service";

//#region Fetch moneyboxes
export const fetchMoneyboxes = ({
  page,
  limit,
  reset,
  filters,
  sort,
}: FetchMoneyboxesRequest) => (dispatch: Dispatch<MoneyboxesFetchTypes>) => {
  dispatch(fetchingMoneyboxes(reset, filters, sort));

  return moneyboxesService
    .getMoneyboxes({
      page,
      limit,
      reset,
      filters,
      sort,
    })
    .then(
      (response) => {
        dispatch(fetchMoneyboxesSuccess(response!));
      },
      (error) => {
        dispatch(fetchMoneyboxesError(error));
      }
    );
};

const fetchingMoneyboxes = (
  reset: boolean,
  filters: MoneyboxFilters,
  sort: MoneyboxSort | null
): MoneyboxesFetchTypes => ({
  type: MONEYBOXES_FETCHING,
  reset,
  filters,
  sort,
});

const fetchMoneyboxesSuccess = (
  moneyboxResponse: MoneyboxResponse
): MoneyboxesFetchTypes => ({
  type: MONEYBOXES_FETCH_SUCCESS,
  moneyboxResponse,
});

const fetchMoneyboxesError = (error: string): MoneyboxesFetchTypes => ({
  type: MONEYBOXES_FETCH_ERROR,
  error,
});
//#endregion

//#region Disable moneybox
export const disableMoneybox = (id: string) => (
  dispatch: Dispatch<MoneyboxDisableTypes>
) => {
  dispatch(disablingMoneybox());

  return moneyboxesService.disableMoneybox(id).then(
    (response) => {
      dispatch(disableMoneyboxSuccess(id));
    },
    (error) => {
      dispatch(disableMoneyboxError(error));
    }
  );
};

const disablingMoneybox = (): MoneyboxDisableTypes => ({
  type: MONEYBOXES_DISABLING,
});

const disableMoneyboxSuccess = (id: string): MoneyboxDisableTypes => ({
  type: MONEYBOXES_DISABLE_SUCCESS,
  id,
});

const disableMoneyboxError = (error: string): MoneyboxDisableTypes => ({
  type: MONEYBOXES_DISABLE_ERROR,
  error,
});
//#endregion

//#region Fetch moneyboxes totals
export const fetchMoneyboxesTotals = () => (
  dispatch: Dispatch<MoneyboxesFetchTotalsTypes>
) => {
  dispatch(fetchingMoneyboxesATotals());
  return moneyboxesService.getMoneyboxesTotals().then(
    (response) => {
      dispatch(fetchMoneyboxesTotalsSuccess(response!));
    },
    (error) => {
      dispatch(fetchMoneyboxesTotalsError(error));
    }
  );
};

const fetchingMoneyboxesATotals = (): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCHING_TOTALS,
});

const fetchMoneyboxesTotalsSuccess = (
  response: MoneyboxTotalsResponse
): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCH_TOTALS_SUCCESS,
  response,
});

const fetchMoneyboxesTotalsError = (
  error: string
): MoneyboxesFetchTotalsTypes => ({
  type: MONEYBOXES_FETCH_TOTALS_ERROR,
  error,
});
//#endregion

//#region Fetch active moneyboxes
export const fetchActiveMoneyboxes = () => (
  dispatch: Dispatch<MoneyboxesFetchActiveTypes>
) => {
  dispatch(fetchingMoneyboxesActive());
  return moneyboxesService.getActiveMoneyboxes().then(
    (response) => {
      dispatch(fetchMoneyboxesActiveSuccess(response!.rowCount));
    },
    (error) => {
      dispatch(fetchMoneyboxesActiveError(error));
    }
  );
};

const fetchingMoneyboxesActive = (): MoneyboxesFetchActiveTypes => ({
  type: MONEYBOXES_FETCHING_ACTIVE,
});

const fetchMoneyboxesActiveSuccess = (
  total: number
): MoneyboxesFetchActiveTypes => ({
  type: MONEYBOXES_FETCH_ACTIVE_SUCCESS,
  total,
});

const fetchMoneyboxesActiveError = (
  error: string
): MoneyboxesFetchActiveTypes => ({
  type: MONEYBOXES_FETCH_ACTIVE_ERROR,
  error,
});
//#endregion

//#region Sort moneyboxes
export const sortMoneyboxes = (
  sortBy: keyof SortMoneyboxValues,
  order: Order
): MoneyboxesSorting => {
  return {
    type: MONEYBOXES_SORT,
    sortBy,
    order,
  };
};

//#endregion
