import * as Yup from 'yup';

const requiredString = 'Campo requerido';
const passwordFormatString =
  'La contraseña debe tener al menos una mayúscula, una minúscula, un número y un caracter especial';
const mailString = 'Formato de mail inválido';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

const LoginSchema = Yup.object().shape({
  name: Yup.string().email(mailString).required(requiredString),
  password: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string().required(requiredString),
  newPassword: Yup.string().required(requiredString).matches(passwordRegex, passwordFormatString),
  newPasswordConfirm: Yup.string()
    .required(requiredString)
    .oneOf([Yup.ref('newPassword')], 'Las contraseñas no coinciden'),
});

const EmailSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const schemas = {
  LoginSchema,
  ResetPasswordSchema,
  ChangePasswordSchema,
  EmailSchema,
};

export default schemas;
