import { get } from "./base.service";
import { FetchTransactionsRequest, TransactionsResponse } from "../store/config/types";
import { endpoints } from "./endpoints";

export const transactionsService = {
  fetchTransactions,
};

async function fetchTransactions({
  page,
  limit,
  reset,
  filters,
}: FetchTransactionsRequest): Promise<TransactionsResponse | undefined> {
  let filtersString = `&from=${filters.from.toISOString()}&to=${filters.to.toISOString()}`;
  if(filters.issuer !== ""){
    filtersString = filtersString + `&issuer=${filters.issuer.toString()}`;
  }
console.log(filtersString)
  return await get<TransactionsResponse>(
    `${endpoints["getTransactions"]}?page=${page}&limit=${limit}${filtersString}`,
    true
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
