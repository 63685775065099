import React from "react";
import { Snackbar, SnackbarContent, Slide, makeStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useSnackbarStyles = makeStyles({
  snackbar: {
    maxWidth: 600,
  },
  message: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 15,
  },
});

//Types
//0 - Success
//1 - Error

interface Type {
  id: number;
  color: string;
  icon: React.ReactElement;
}

const types: Type[] = [
  {
    id: 0,
    color: "#4caf50",
    icon: <ErrorIcon />,
  },
  {
    id: 1,
    color: "#f44336",
    icon: <CheckCircleIcon />,
  },
];

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  autoHide?: number;
  handleClose(event: React.SyntheticEvent<any>): void;
  type: number;
}

function CustomSnackbar({ open, message, autoHide, handleClose, type }: CustomSnackbarProps) {
  const classes = useSnackbarStyles();

  let selectedType = types.filter((t) => {
    if (t.id === type) {
      return t;
    }
    return null;
  })[0];

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? autoHide : 3000}
      TransitionComponent={Slide}
      onClose={handleClose}
      classes={{ root: classes.snackbar }}
    >
      <SnackbarContent
        classes={{ message: classes.message }}
        style={{ backgroundColor: selectedType.color }}
        message={
          <>
            <span className={classes.icon}>{selectedType.icon}</span>
            {message}
          </>
        }
      />
    </Snackbar>
  );
}

export default CustomSnackbar;
