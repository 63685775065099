import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CommerceDialog } from '../../components/commerces/CommerceDialog';
import { CommercesList } from '../../components/commerces/CommercesList';
import LoadAnimation from '../../components/common/animations/LoadAnimation';
import { fetchIssuers, fetchSupportedIssuers } from '../../store/action_creators/issuers.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { Issuer, RootState } from '../../store/config/types';
import EmailVerification from './EmailVerification';

const mapStateToProps = (state: RootState) => ({
  issuers: state.issuers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchIssuers: () => dispatch(fetchIssuers()),
  fetchSupportedIssuers: () => dispatch(fetchSupportedIssuers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Commerces({ issuers, fetchIssuers, fetchSupportedIssuers }: PropsFromRedux) {
  const [selectedIssuer, setSelectedIssuer] = useState<Issuer | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!issuers.loadingIssuers && !issuers.issuers && !issuers.issuersErrorMessage) {
      fetchIssuers();
    }
  }, [issuers.loadingIssuers, issuers.issuers, issuers.issuersErrorMessage, fetchIssuers]);

  useEffect(() => {
    if (
      !issuers.loadingSupportedIssuers &&
      !issuers.supportedIssuers &&
      !issuers.supportedIssuersErrorMessage
    ) {
      fetchSupportedIssuers();
    }
  }, [
    issuers.loadingSupportedIssuers,
    issuers.supportedIssuers,
    issuers.supportedIssuersErrorMessage,
    fetchSupportedIssuers,
  ]);

  return (
    <div className="commerces">
      <h2>Verificación de mails</h2>
      <EmailVerification />
      <h2>Comercios</h2>
      {issuers.issuers && issuers.supportedIssuers ? (
        <>
          <CommercesList
            issuers={issuers.issuers}
            supportedIssuers={issuers.supportedIssuers}
            setOpen={setOpen}
            setSelectedIssuer={setSelectedIssuer}
          />
          <CommerceDialog
            open={open}
            setOpen={setOpen}
            supportedIssuers={issuers.supportedIssuers}
            selectedIssuer={selectedIssuer}
          />
        </>
      ) : (
        <div className="loading-div">
          <LoadAnimation />
        </div>
      )}
    </div>
  );
}

export default connector(Commerces);
