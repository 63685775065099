import React, { useState } from "react";
import logo from "../../../assets/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import BurgerToggle from "./BurgerToggle";
import { Button, Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface HeaderPropTypes {
  isLoggedIn: boolean;
  currentUrl: string;
  logout: () => void;
  redirect: (path: string) => void;
}

const options = [
  {
    name: "Inicio",
    icon: <HomeIcon fontSize="small" />,
    route: "/dashboard",
  },
  {
    name: "Perfil",
    icon: <PersonIcon fontSize="small" />,
    route: "/profile",
  },
  {
    name: "Configuración",
    icon: <SettingsIcon fontSize="small" />,
    route: "/commerces",
  },
];

function Header({ isLoggedIn, currentUrl, logout, redirect }: HeaderPropTypes) {
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const toggleMobileMenu = (value: boolean) => {
    setMobileMenuOpened(value);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <div className="header">
      <div className="header-content">
        <img src={logo} alt="logo" onClick={() => redirect("/")} />
        {isLoggedIn ? (
          <>
            <div className="header-actions">
              <span
                className={`${currentUrl === "/dashboard" && "header-action-active"} header-action`}
                onClick={() => redirect("/dashboard")}
              >
                <HomeIcon fontSize="small" /> Inicio
              </span>
              <span
                className={`${currentUrl === "/profile" && "header-action-active"} header-action`}
                onClick={() => redirect("/profile")}
              >
                <PersonIcon fontSize="small" /> Perfil
              </span>
              <span
                className={`${currentUrl === "/commerces" && "header-action-active"} header-action`}
                onClick={() => redirect("/commerces")}
              >
                <SettingsIcon fontSize="small" /> Configuración
              </span>
              <span className="header-action" onClick={() => setOpen(true)}>
                <ExitToAppIcon fontSize="small" /> Salir
              </span>
            </div>
            <div className="mobile-header">
              <BurgerToggle mobileMenuOpened={mobileMenuOpened} toggleMobileMenu={toggleMobileMenu} />
              <Slide timeout={700} direction="up" in={mobileMenuOpened}>
                <div className="mobile-header-options">
                  {options.map((o, index) => {
                    return (
                      <span
                        className="option"
                        key={index}
                        onClick={() => {
                          redirect(o.route);
                          toggleMobileMenu(!mobileMenuOpened);
                        }}
                      >
                        {o.icon}
                        {o.name}
                      </span>
                    );
                  })}
                  <span
                    className="option"
                    onClick={() => {
                      setOpen(true);
                      toggleMobileMenu(!mobileMenuOpened);
                    }}
                  >
                    <ExitToAppIcon fontSize="small" /> Salir
                  </span>
                </div>
              </Slide>
            </div>
          </>
        ) : (
          <span>Alcancías Teletón</span>
        )}
      </div>
      <Dialog className="logout" fullWidth maxWidth="xs" open={open} onClose={handleDialogClose}>
        <DialogTitle className="title">
          <CloseIcon className="blue-link" onClick={handleDialogClose} />
        </DialogTitle>
        <DialogContent className="content">
          <span>¿Estás seguro de que deseas cerrar sesión?</span>
          <div className="actions">
            <span className="blue-link" onClick={handleDialogClose}>
              Cancelar
            </span>
            <Button
              className="confirm-button"
              color="secondary"
              variant="contained"
              onClick={() => {
                logout();
                handleDialogClose();
              }}
            >
              Cerrar sesión
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Header;
