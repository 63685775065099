import React, { useState } from "react";
import { RootState, ResetPasswordRequest } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { resetPassword } from "../../store/action_creators/auth.actions";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../components/forms/FormTextField";
import schemas from "../../helpers/schemas";

interface Values {
  email: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  resetPassword: (request: ResetPasswordRequest) =>
    dispatch(resetPassword(request)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function ResetPassword({ history, auth, resetPassword }: PropsType) {
  const [resettingPassword, setResettingPassword] = useState(false);

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  const submit = (values: Values) => {
    setResettingPassword(true);
    resetPassword(values);
  };

  const handleClose = () => {
    setResettingPassword(false);
  };

  return (
    <div className="reset-password">
      <div className="title">
        <div className="left">
          <h2>Recuperar contraseña</h2>
          <p>
            Ingresá el mail con el cual te registraste y te enviaremos la
            contraseña.
          </p>
        </div>
        <span className="blue-link" onClick={() => redirectTo("/login")}>
          Volver
        </span>
      </div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={schemas.ResetPasswordSchema}
        onSubmit={(values, actions) => {
            submit(values);
            actions.setSubmitting(false);
        }}
      >
        {(props) => (
          <Form className="form reset-password-form">
            <Field
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Email"
            />
            <div className="actions-row">
              <div className="reset-password-action">
              {!auth.resettingPassword ? (<Button
                color="secondary"
                variant="contained"
                type="submit"
                disabled={
                  props.values.email === "" ||
                  props.errors.email !== undefined ||
                  props.isSubmitting
                }
              >
                Recuperar contraseña
              </Button>) : (<CircularProgress color="secondary" />)}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={
          (auth.resetPasswordSuccess ||
            auth.resetPasswordErrorMessage !== null) &&
          resettingPassword
        }
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={auth.resetPasswordSuccess ? "success" : "error"}
        >
          {auth.resetPasswordSuccess
            ? "Revisá tu casilla de correo para reiniciar tu contraseña."
            : "Ocurrió un error al reiniciar tu contraseña."}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default connector(withRouter(ResetPassword));
