import { FetchMoneyboxesRequest, MoneyboxResponse, MoneyboxTotalsResponse } from "../store/config/types";
import { get, httpDelete } from "./base.service";
import { endpoints } from "./endpoints";

export const moneyboxesService = {
  getMoneyboxes,
  getActiveMoneyboxes,
  disableMoneybox,
  getMoneyboxesTotals,
};

async function getMoneyboxes({
  page,
  limit,
  reset,
  filters,
  sort,
}: FetchMoneyboxesRequest): Promise<MoneyboxResponse | undefined> {
  let filtersString = filters.user !== "" ? `&user=${filters.user}` : "";
  if (filters.status !== "-1") {
    filtersString += `&status=${filters.status}`;
  }
  let sortString = sort ? `&sortBy=${sort.sortBy}&ascending=${sort.ascending}` : "";
  return await get<MoneyboxResponse>(
    `${endpoints["getMoneyboxes"]}?page=${page}&limit=${limit}${filtersString}${sortString}`,
    true,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getActiveMoneyboxes(): Promise<MoneyboxResponse | undefined> {
  return await get<MoneyboxResponse>(`${endpoints["getActivemoneyboxes"]}&limit=1`, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getMoneyboxesTotals(): Promise<MoneyboxTotalsResponse | undefined> {
  return await get<MoneyboxTotalsResponse>(
    `${endpoints["getMoneyboxesTotals"]}?from=2005-01-01&to=2200-01-01`,
    true,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function disableMoneybox(id: string): Promise<string | undefined> {
  return await httpDelete<string>(`${endpoints["disableMoneybox"]}/${id}`, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
