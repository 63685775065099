import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import { authService } from '../../services/auth.service';
import { User, ChangePasswordRequest, ResetPasswordRequest } from '../config/types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LoginActionTypes,
  PASSWORD_CHANGING,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  PasswordChangeTypes,
  PASSWORD_RESETTING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  PasswordResetTypes,
  LOGOUT,
  LogoutTypes,
  VerifyEmailTypes,
  VERIFYING_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
} from '../config/ActionTypes';

//#region Login

export const login = (user: User) => (dispatch: Dispatch<LoginActionTypes>) => {
  dispatch(loggingIn());
  return authService.login({ user }).then(
    (response) => {
      const cookies = new Cookies();
      cookies.set('token', response!);
      dispatch(loginSuccess(user.email));
    },
    (error) => {
      dispatch(loginError(error));
    }
  );
};

const loggingIn = (): LoginActionTypes => ({
  type: LOGGING_IN,
});

const loginSuccess = (email: string): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  email,
});

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
});

//#endregion

//#region Change password

export const changePassword =
  (request: ChangePasswordRequest) => (dispatch: Dispatch<PasswordChangeTypes>) => {
    dispatch(changingPassword());

    return authService.changePassword(request).then(
      (response) => {
        dispatch(changePasswordSuccess());
      },
      (error) => {
        dispatch(changePasswordError(error));
      }
    );
  };

const changingPassword = (): PasswordChangeTypes => ({
  type: PASSWORD_CHANGING,
});

const changePasswordSuccess = (): PasswordChangeTypes => ({
  type: PASSWORD_CHANGE_SUCCESS,
});

const changePasswordError = (error: string): PasswordChangeTypes => ({
  type: PASSWORD_CHANGE_ERROR,
  error,
});

//#endregion

//#region Reset password

export const resetPassword = (request: ResetPasswordRequest) => (dispatch: Dispatch<PasswordResetTypes>) => {
  dispatch(resettingPassword());

  return authService.resetPassword(request).then(
    (response) => {
      dispatch(resetPasswordSuccess());
    },
    (error) => {
      dispatch(resetPasswordError(error));
    }
  );
};

const resettingPassword = (): PasswordResetTypes => ({
  type: PASSWORD_RESETTING,
});

const resetPasswordSuccess = (): PasswordResetTypes => ({
  type: PASSWORD_RESET_SUCCESS,
});

const resetPasswordError = (error: string): PasswordResetTypes => ({
  type: PASSWORD_RESET_ERROR,
  error,
});

//#endregion

//#region Verify email

export const verifyEmail = (email: string) => (dispatch: Dispatch<VerifyEmailTypes>) => {
  dispatch(verifyingEmail());

  return authService.verifyEmail(email).then(
    (response) => {
      dispatch(verifyEmailSuccess());
    },
    (error) => {
      dispatch(verifyEmailError(error));
    }
  );
};

const verifyingEmail = (): VerifyEmailTypes => ({
  type: VERIFYING_EMAIL,
});

const verifyEmailSuccess = (): VerifyEmailTypes => ({
  type: VERIFY_EMAIL_SUCCESS,
});

const verifyEmailError = (error: string): VerifyEmailTypes => ({
  type: VERIFY_EMAIL_ERROR,
  error,
});

//#endregion

//#region Logout

export const logout = () => (dispatch: Dispatch<LogoutTypes>) => {
  const cookies = new Cookies();
  cookies.remove('token');
  dispatch(signout());
};

const signout = (): LogoutTypes => ({
  type: LOGOUT,
});

//#endregion
