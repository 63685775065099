import { get, httpDelete, post } from "./base.service";
import { Issuer, SupportedIssuer, CreateIssuerRequest } from "../store/config/types";
import { endpoints } from "./endpoints";

export const issuersService = {
  fetchIssuers,
  createIssuer,
  deleteIssuer,
  fetchSupportedIssuers,
};

async function fetchIssuers(): Promise<Issuer[] | undefined> {
  return await get<Issuer[]>(`${endpoints["issuers"]}`, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createIssuer(createIssuerRequest: CreateIssuerRequest): Promise<Issuer | undefined> {
  return await post<Issuer>(`${endpoints["issuers"]}`, createIssuerRequest, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function deleteIssuer(issuerId: number): Promise<string | undefined> {
  return await httpDelete<string>(`${endpoints["issuers"]}/${issuerId}`, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function fetchSupportedIssuers(): Promise<SupportedIssuer[] | undefined> {
  return await get<SupportedIssuer[]>(`${endpoints["issuers"]}/supported`, true)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
