import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../components/forms/FormTextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RootState, User } from "../../store/config/types";
import { login } from "../../store/action_creators/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import schemas from "../../helpers/schemas";

interface Values {
  name: string;
  password: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, AppActions>
) => ({
  login: (user: User) => dispatch(login(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Login({ history, auth, login }: PropsType) {
  const [loggingIn, setLoggingIn] = useState(false);

  const redirectTo = (path: string): void => {
    history.push(path);
  };

  const submit = (values: Values) => {
    setLoggingIn(true);
    const user = {
      email: values.name,
      password: values.password,
    };
    login(user);
  };

  const handleClose = () => {
    setLoggingIn(false);
  };

  return (
    <div className="login">
      <div className="title">
        <div className="left">
          <h2>Ingresá al panel de administración de Alcancías Teletón</h2>
          <p>Completá los datos a continuación.</p>
        </div>
      </div>
      <Formik
        initialValues={{ name: "", password: "" }}
        validationSchema={schemas.LoginSchema}
        onSubmit={submit}
      >
        {(props) => (
          <Form className="form login-form">
            <Field
              name="name"
              component={FormTextField}
              type="text"
              placeholder="Email"
            />
            <Field
              name="password"
              component={FormTextField}
              type="password"
              placeholder="Contraseña"
            />
            <div className="actions-row">
              <p
                className="blue-link"
                onClick={() => redirectTo("/resetPassword")}
              >
                ¿Olvidaste tu contraseña?
              </p>
              <div className="login-button">
                {!auth.loggingIn ? (
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      props.values.name === "" ||
                      props.values.password === "" ||
                      !props.isValid
                    }
                  >
                    Ingresar
                  </Button>
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={auth.logInErrorMessage !== null && loggingIn}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          Ocurrió un error al iniciar la sesión. Verifica los datos ingresados.
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default connector(withRouter(Login));
