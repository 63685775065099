import Cookies from "universal-cookie";




export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  stringBody?: string;
}

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  const cookies = new Cookies();

  await response.text().then((body) => {
    try {
      response.parsedBody = JSON.parse(body);
    } catch (ex) {
      response.stringBody = body;
    }
  });

  if (!response.ok) {
    if(response.status === 401){
      cookies.remove("token");
      window.location.reload();
    }
    throw new Error(response.statusText);
  }

  return response;
}

function getHeaders(hasAuth: boolean) {
  let headers = new Headers({
    "Content-Type": "application/json",
  });

  const cookies = new Cookies();

  if (hasAuth) {
    headers.append("Authorization", `Bearer ${cookies.get("token")}`);
  }

  return headers;
}

export async function get<T>(
  path: string,
  hasAuth: boolean = false,
  args: RequestInit = {
    method: "get",
    headers: getHeaders(hasAuth),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  hasAuth: boolean = false,
  args: RequestInit = {
    method: "post",
    headers: getHeaders(hasAuth),
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  hasAuth: boolean = false,
  args: RequestInit = {
    method: "put",
    headers: getHeaders(hasAuth),
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function httpDelete<T>(
  path: string,
  hasAuth: boolean = false,
  args: RequestInit = { method: "delete", headers: getHeaders(hasAuth) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
