import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Badge,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { FetchTransactionsRequest, RootState, TransactionsFilters } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { fetchTransactions } from "../../../store/action_creators/transactions.actions";
import { fetchSupportedIssuers } from "../../../store/action_creators/issuers.actions";
import { connect, ConnectedProps } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LoadAnimation from "../../common/animations/LoadAnimation";
import EmptyAnimation from "../../common/animations/EmptyAnimation";

const mapStateToProps = (state: RootState) => ({
  transactions: state.transactions,
  issuers: state.issuers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchTransactions: ({ page, limit, reset, filters }: FetchTransactionsRequest) =>
    dispatch(fetchTransactions({ page, limit, reset, filters })),
  fetchSupportedIssuers: () => dispatch(fetchSupportedIssuers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TransactionsList({ transactions, fetchTransactions, issuers, fetchSupportedIssuers }: PropsFromRedux) {
  const [from, setFrom] = useState<moment.Moment>(transactions.filters.from);
  const [to, setTo] = useState<moment.Moment>(transactions.filters.to);
  const [issuer, setIssuer] = useState<string>(transactions.filters.issuer === "" ? "-1" : transactions.filters.issuer);

  useEffect(() => {
    if (!transactions.loadingTransactions && !transactions.transactions && !transactions.loadTransactionsErrorMessage) {
      const filters: TransactionsFilters = {
        from: moment().add(-1, "years"),
        to: moment(),
        issuer: "",
      };
      fetchTransactions({ page: 1, limit: 15, reset: false, filters });
    }
  }, [
    transactions.loadingTransactions,
    transactions.transactions,
    transactions.loadTransactionsErrorMessage,
    fetchTransactions,
  ]);

  useEffect(() => {
    if (!issuers.loadingSupportedIssuers && !issuers.supportedIssuers && !issuers.supportedIssuersErrorMessage) {
      fetchSupportedIssuers();
    }
  }, [
    issuers.loadingSupportedIssuers,
    issuers.supportedIssuers,
    issuers.supportedIssuersErrorMessage,
    fetchSupportedIssuers,
  ]);

  const loadMore = () => {
    fetchTransactions({
      page: transactions.currentPage + 1,
      limit: 15,
      reset: false,
      filters: transactions.filters,
    });
  };

  const formatDate = (date: Date) => {
    return moment(date).subtract(3, 'h').format("DD/MM/YYYY");
  };

  const formatTime = (date: Date) => {
    return moment(date).subtract(3, 'h').format("HH:mm:ss")
  }

  //Currency
  //1: PESO
  //2: DOLAR

  const formatCurrency = (currency: number) => {
    if (currency === 1) {
      return "$";
    } else {
      return "U$S";
    }
  };

  //Payment status
  //0: Created
  //1: Success
  //2: Failed

  const formatStatus = (status: number) => {
    return (
      <Badge
        color="primary"
        className={status === 0 ? "created-dot" : status === 1 ? "success-dot" : "error-dot"}
        variant="dot"
      />
    );
  };

  const applyFilters = (filters: TransactionsFilters) => {
    fetchTransactions({ page: 1, limit: 15, reset: true, filters });
  };

  const changeFrom = (date: any) => {
    setFrom(moment(date));

    applyFilters({
      ...transactions.filters,
      from: moment(date),
    });
  };

  const changeTo = (date: any) => {
    setTo(moment(date));

    applyFilters({
      ...transactions.filters,
      to: moment(date),
    });
  };

  const handleIssuerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    
      applyFilters({
        ...transactions.filters,
        issuer: event.target.value !== "-1" ? event.target.value as string : "",
      });
    
    setIssuer(event.target.value as string);
  };

  return (
    <div className="transactions-list">
      <div className="transactions-filters">
        <KeyboardDatePicker
          value={from}
          onChange={changeFrom}
          label="Desde"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          disableFuture={true}
          className="date-picker"
        />
        <KeyboardDatePicker
          value={to}
          onChange={changeTo}
          label="Hasta"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          disableFuture={true}
          className="date-picker"
        />
        {issuers?.supportedIssuers?.length !== undefined && issuers?.supportedIssuers?.length > 0 && (
          <FormControl variant="outlined" className="field">
            <InputLabel id="demo-simple-select-outlined-label">Sello</InputLabel>
            <Select name="status" label="Estado" value={issuer} onChange={handleIssuerChange}>
              <MenuItem value="-1">Todos</MenuItem>
              {issuers.supportedIssuers.map((i) => (
                <MenuItem value={i.issuer}>{i.issuer}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <InfiniteScroll
        hasMore={transactions.currentPage < transactions.pageCount}
        next={loadMore}
        dataLength={transactions.transactions ? transactions.transactions.length : 0}
        loader={
          //transactions.loadingTransactions &&
          (<div className="loading-div">
            <LoadAnimation />{" "}
          </div>)
        }
      >
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell className="transaction-issuer-column" align="center">
                  Sello
                </TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="right">Monto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.transactions &&
                transactions.transactions.map((t, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{formatDate(t.created)}</TableCell>
                      <TableCell>{formatTime(t.created)}</TableCell>
                      <TableCell className="transaction-issuer-column" align="center">
                        {t.instrument.issuerName}
                      </TableCell>
                      <TableCell align="center">{formatStatus(t.status)}</TableCell>
                      <TableCell align="right">{`${formatCurrency(t.amount.currency)} ${t.amount.value}`}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
      {!transactions.loadingTransactions &&
        (transactions.transactions === undefined || transactions.transactions!?.length <= 0) && (
          <div className="empty-div">
            <EmptyAnimation />
            <Typography component="p" variant="body2">
              No se encontraron donaciones para los filtros seleccionados
            </Typography>
          </div>
        )}
    </div>
  );
}

export default connector(TransactionsList);
