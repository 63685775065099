import React, { useEffect } from "react";
import { withRouter, Switch, Route, RouteComponentProps } from "react-router-dom";
import { Header } from "../components/common/Header";
import { Login } from "../screens/Login";
import { Dashboard } from "../screens/Dashboard";
import { UserProfile } from "../screens/UserProfile";
import { ResetPassword } from "../screens/ResetPassword";
import { Commerces } from "../screens/Commerces";
import Cookies from "universal-cookie";
import { RootState } from "../store/config/types";
import { ConnectedProps, connect } from "react-redux";
import { urlHelper } from "../helpers/urlHelper";
import { logout } from "../store/action_creators/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/config/ActionTypes";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  logout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Main({ auth, logout, history }: PropsType) {
  const cookies = new Cookies();
  const isLoggedIn = cookies.get("token") !== undefined;
  
  useEffect(() => {
    if (!isLoggedIn) {
      if (!urlHelper.isOnPublicUrl(history.location.pathname)) {
        history.push("/login");
      }
    } else if (urlHelper.isOnPublicUrl(history.location.pathname)) {
      history.push("/dashboard");
    }
  }, [auth.logInErrorMessage, isLoggedIn, history]);

  return (
    <div className="main">
      <Header
        isLoggedIn={isLoggedIn}
        logout={logout}
        redirect={(path) => {
          history.push(path);
        }}
        currentUrl={history.location.pathname}
      />
      <div className="content">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/profile" component={UserProfile} />
          <Route path="/commerces" component={Commerces} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/" component={isLoggedIn ? Dashboard : Login} />
        </Switch>
      </div>
    </div>
  );
}

export default connector(withRouter(Main));
