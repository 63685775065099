import React from "react";
import "./styles/app.scss";
import { Provider } from "react-redux";
import { store } from "./store/config/configureStore";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import Main from "./screens/Main";
import { theme } from "./styles/theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Main />
          </BrowserRouter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
