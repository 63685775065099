import { Order } from "../store/config/types";

export const comparator = {
  compareValues,
};

export function compareValues(key: string, order: Order = "asc", nestedKey?: string) {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    let varA, varB;
    if (nestedKey === undefined || nestedKey === "") {
      varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    } else {
      varA =
        typeof a[key][nestedKey] === "string"
          ? a[key][nestedKey].toUpperCase()
          : a[key][nestedKey];
      varB =
        typeof b[key][nestedKey] === "string"
          ? b[key][nestedKey].toUpperCase()
          : b[key][nestedKey];
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}
