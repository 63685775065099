import { MoneyboxesState } from "../config/types";
import {
  MONEYBOXES_FETCHING,
  MONEYBOXES_FETCH_SUCCESS,
  MONEYBOXES_FETCH_ERROR,
  MONEYBOXES_DISABLING,
  MONEYBOXES_DISABLE_SUCCESS,
  MONEYBOXES_DISABLE_ERROR,
  MONEYBOXES_FETCHING_TOTALS,
  MONEYBOXES_FETCH_TOTALS_SUCCESS,
  MONEYBOXES_FETCH_TOTALS_ERROR,
  MONEYBOXES_SORT,
  MoneyboxesDataTypes,
} from "../config/ActionTypes";
import { comparator } from "../../helpers/comparatorHelper";

const initialState: MoneyboxesState = {
  fetchingMoneyboxes: false,
  fetchMoneyboxesSuccess: false,
  fetchMoneyboxesErrorMessage: null,
  filters: { user: "", status: "" },
  sort: null,
  fetchingTotals: false,
  totals: null,
  fetchTotalsErrors: null,
  moneyboxes: null,
  disablingMoneybox: false,
  disableMoneyboxSuccess: false,
  disableMoneyboxErrorMessage: null,
};

export function moneyboxesReducer(
  state = initialState,
  action: MoneyboxesDataTypes
) {
  switch (action.type) {
    case MONEYBOXES_FETCHING:
      let newResults = action.reset ? [] : state.moneyboxes?.results;
      let newMoneyboxResponse = state.moneyboxes
        ? { ...state.moneyboxes, results: newResults }
        : state.moneyboxes;
      let newFilters = action.reset ? action.filters : state.filters;
      let newSort = action.reset ? action.sort : state.sort;
      return {
        ...state,
        fetchingMoneyboxes: true,
        fetchMoneyboxesSuccess: false,
        fetchMoneyboxesErrorMessage: null,
        moneyboxes: { ...newMoneyboxResponse },
        filters: newFilters,
        sort: newSort,
      };
    case MONEYBOXES_FETCH_SUCCESS:
      let moneyboxes = state.moneyboxes?.results;

      if (moneyboxes) {
        moneyboxes = moneyboxes.concat(action.moneyboxResponse.results);
      } else {
        moneyboxes = action.moneyboxResponse.results;
      }
      return {
        ...state,
        fetchingMoneyboxes: false,
        fetchMoneyboxesSuccess: true,
        fetchMoneyboxesErrorMessage: null,
        moneyboxes: { ...action.moneyboxResponse, results: moneyboxes },
      };
    case MONEYBOXES_FETCH_ERROR:
      return {
        ...state,
        fetchingMoneyboxes: false,
        fetchMoneyboxesSuccess: false,
        fetchMoneyboxesErrorMessage: action.error,
        moneyboxes: null,
      };
    case MONEYBOXES_DISABLING:
      return {
        ...state,
        disablingMoneyboxes: true,
        disableMoneyboxSuccess: false,
        disableMoneyboxErrorMessage: null,
      };
    case MONEYBOXES_DISABLE_SUCCESS:
      const newMoneyboxes = state.moneyboxes?.results.map((m) => {
        if (m.id === action.id) {
          m.status = 99;
        }
        return m;
      });
      let newTotal = state.totals ? state.totals.totalActiveMoneyboxes : 0;
      if (newTotal > 0) {
        newTotal--;
      }
      return {
        ...state,
        disablingMoneyboxes: true,
        disableMoneyboxSuccess: false,
        disableMoneyboxErrorMessage: null,
        totals: {
          ...state.totals,
          totalActiveMoneyboxes: newTotal,
        },
        moneyboxes: {
          ...state.moneyboxes,
          results: newMoneyboxes,
        },
      };
    case MONEYBOXES_DISABLE_ERROR:
      return {
        ...state,
        disablingMoneyboxes: false,
        disableMoneyboxSuccess: false,
        disableMoneyboxErrorMessage: action.error,
      };
    case MONEYBOXES_FETCHING_TOTALS:
      return {
        ...state,
        fetchingTotals: true,
        totals: null,
        fetchTotalsErrors: null,
      };
    case MONEYBOXES_FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        fetchingTotals: false,
        totals: action.response,
        fetchTotalsErrors: null,
      };
    case MONEYBOXES_FETCH_TOTALS_ERROR:
      return {
        ...state,
        fetchingTotals: false,
        totals: null,
        fetchTotalsErrors: action.error,
      };
    case MONEYBOXES_SORT: {
      const sortedMoneyboxes = state.moneyboxes?.results.sort(
        comparator.compareValues(
          action.sortBy,
          action.order,
          action.sortBy === "admin" ? "email" : ""
        )
      );
      return {
        ...state,
        moneyboxes: {
          ...state.moneyboxes,
          results: sortedMoneyboxes,
        },
      };
    }
    default:
      return state;
  }
}
