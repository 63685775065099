import {
  ADD_TRANSACTIONS,
  LOADING_TRANSACTIONS,
  TransactionsDataTypes,
  TRANSACTIONS_ERROR,
  TRANSACTIONS_SORT,
} from "../config/ActionTypes";
import { TransactionsState } from "../config/types";
import moment from "moment";
import { comparator } from "../../helpers/comparatorHelper";

const initialState: TransactionsState = {
  loadingTransactions: false,
  loadTransactionsSuccess: false,
  loadTransactionsErrorMessage: null,
  transactions: null,
  currentPage: 0,
  pageCount: 0,
  rowCount: 0,
  filters: {
    from: moment().add(-1, "months"),
    to: moment(),
    issuer: "",
  },
};

export function transactionsReducer(
  state = initialState,
  action: TransactionsDataTypes
) {
  switch (action.type) {
    case LOADING_TRANSACTIONS:
      let newTransactions = action.reset ? [] : state.transactions;
      let newFilters = action.reset ? action.filters : state.filters;

      return {
        ...state,
        loadingTransactions: true,
        loadTransactionsSuccess: false,
        loadTransactionsErrorMessage: null,
        transactions: newTransactions,
        filters: newFilters,
      };

    case ADD_TRANSACTIONS:
      let transactions = state.transactions;

      if (transactions) {
        transactions = transactions.concat(action.transactionsResponse.results);
      } else {
        transactions = action.transactionsResponse.results;
      }

      return {
        ...state,
        loadingTransactions: false,
        loadTransactionsSuccess: true,
        loadTransactionsErrorMessage: null,
        transactions,
        currentPage: action.transactionsResponse.currentPage,
        pageCount: action.transactionsResponse.pageCount,
        rowCount: action.transactionsResponse.rowCount,
      };

    case TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingTransactions: false,
        loadTransactionsSuccess: false,
        loadTransactionsErrorMessage: action.error,
        transactions: null,
        currentPage: 0,
        pageCount: 0,
        rowCount: 0,
      };
    case TRANSACTIONS_SORT: {
      const sortedTransactions = state.transactions?.sort(
        comparator.compareValues(action.sortBy, action.order)
      );
      return {
        ...state,
        transactions: sortedTransactions,
      };
    }
    default:
      return state;
  }
}
