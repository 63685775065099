import React, { useState } from "react";
import {
  Moneybox,
  FetchMoneyboxesRequest,
  MoneyboxResponse,
  SortMoneyboxValues,
  Order,
  MoneyboxFilters,
  MoneyboxSort,
} from "../../../store/config/types";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  Badge,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { DisableDialog } from "../../common/DisableDialog";
import InfiniteScroll from "react-infinite-scroll-component";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../forms/FormTextField";
import { SortKeys } from "../../../store/config/enums";
import LoadAnimation from "../../common/animations/LoadAnimation";
import EmptyAnimation from "../../common/animations/EmptyAnimation";

interface MoneyboxesListProps {
  moneyboxes: MoneyboxResponse;
  disableAction: (id: string) => void;
  fetchMoneyboxes: ({ page, limit, reset, filters }: FetchMoneyboxesRequest) => void;
  fetchLimit: number;
  sortMoneyboxes: (sortBy: keyof SortMoneyboxValues, order: Order) => void;
  filters: MoneyboxFilters;
  sort: MoneyboxSort | null;
  loading: boolean;
}

export function MoneyboxesList({
  moneyboxes,
  disableAction,
  fetchMoneyboxes,
  sortMoneyboxes,
  fetchLimit,
  filters,
  sort,
  loading,
}: MoneyboxesListProps) {
  const [openDisable, setOpenDisable] = useState<boolean>(false);
  const [selectedMoneybox, setSelectedMoneybox] = useState<Moneybox | null>(null);

  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const sortBy = (key: SortKeys) => {
    const sortOrder = sort?.sortBy !== key ? "desc" : order === "desc" ? "asc" : "desc";
    const sortObj = sort?.sortBy !== null ? { sortBy: key, ascending: sortOrder === "asc" } : sort;
    fetchMoneyboxes({ page: 1, limit: fetchLimit, reset: true, filters, sort: sortObj });

    setOrder(sortOrder);
  };

  const applyFilters = (filters: MoneyboxFilters) => {
    fetchMoneyboxes({ page: 1, limit: fetchLimit, reset: true, filters, sort });
  };

  const loadMore = () => {
    fetchMoneyboxes({
      page: moneyboxes.currentPage + 1,
      limit: fetchLimit,
      reset: false,
      filters,
      sort,
    });
  };
  return (
    <div className="moneyboxes-list">
      <Formik
        initialValues={{ user: filters.user, status: filters.status === "" ? "-1" : filters.status }}
        onSubmit={(values) => applyFilters({ ...filters, user: values.user, status: values.status })}
      >
        {(props) => (
          <Form className="filter-form">
            <Field className="field" name="user" component={FormTextField} type="text" placeholder="Administrador" />
            <FormControl variant="outlined" className="field">
              <InputLabel id="demo-simple-select-outlined-label">Estado</InputLabel>
              <Select name="status" label="Estado" value={props.values.status} onChange={props.handleChange}>
                <MenuItem value="-1">Todas</MenuItem>
                <MenuItem value="0">Activas</MenuItem>
                <MenuItem value="99">Inactivas</MenuItem>
              </Select>
            </FormControl>
            <Button color="secondary" variant="contained" type="submit">
              Aplicar filtros
            </Button>
          </Form>
        )}
      </Formik>
      <InfiniteScroll
        hasMore={moneyboxes.currentPage < moneyboxes.pageCount}
        next={loadMore}
        dataLength={moneyboxes.results ? moneyboxes.results.length : 0}
        loader={<div className="loading-div">{!loading && <LoadAnimation />}</div>}
      >
        <TableContainer component={Paper} elevation={0}>
          <Table className="dashboard-table">
            <TableHead>
              <TableRow>
                <TableCell className="name-column">Nombre</TableCell>
                <TableCell className="only-desktop admin-column" align="center">
                  Administrador
                </TableCell>
                <TableCell className="only-desktop fantasy-column" align="center">
                  Nombre fantasía
                </TableCell>
                <TableCell className="only-desktop" align="center">
                  <TableSortLabel
                    active={sort?.sortBy === SortKeys.Amount}
                    direction={sort?.ascending ? "asc" : "desc"}
                    onClick={() => sortBy(SortKeys.Amount)}
                  >
                    Recaudación $
                  </TableSortLabel>
                </TableCell>
                <TableCell className="only-desktop" align="center">
                  <TableSortLabel
                    active={sort?.sortBy === SortKeys.AmountDollars}
                    direction={sort?.ascending ? "asc" : "desc"}
                    onClick={() => sortBy(SortKeys.AmountDollars)}
                  >
                    Recaudación US$
                  </TableSortLabel>
                </TableCell>
                <TableCell className="only-mobile" align="center">
                  Recaudación
                </TableCell>
                <TableCell className="only-desktop" align="center">
                  <TableSortLabel
                    active={sort?.sortBy === SortKeys.Donations}
                    direction={sort?.ascending ? "asc" : "desc"}
                    onClick={() => sortBy(SortKeys.Donations)}
                  >
                    Donaciones
                  </TableSortLabel>
                </TableCell>
                <TableCell className="only-desktop" align="center">
                  Estado
                </TableCell>
                <TableCell align="center">Desactivar</TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {moneyboxes.results?.map((m, i) => (
                  <TableRow key={i} hover>
                    <TableCell component="th" scope="row" className="name-column">
                      {m.name}
                    </TableCell>
                    <TableCell className="only-desktop admin-column" align="center">
                      {m.admin.email}
                    </TableCell>
                    <TableCell className="only-desktop fantasy-column" align="center">
                      {m.fantasyName}
                    </TableCell>
                    <TableCell className="only-desktop" align="center">
                      ${m.raisedAmount}
                    </TableCell>
                    <TableCell className="only-desktop" align="center">
                      US${m.raisedAmountDollars}
                    </TableCell>
                    <TableCell className="only-mobile" align="center">
                      <p>${m.raisedAmount}</p>
                      <p>US${m.raisedAmountDollars}</p>
                    </TableCell>
                    <TableCell className="only-desktop" align="center">
                      {m.totalDonations}
                    </TableCell>
                    <TableCell className="only-desktop" align="center">
                      <Badge
                        color={m.status === 0 ? "secondary" : "error"}
                        variant="dot"
                        classes={{
                          colorSecondary: "active-dot",
                          colorError: "inactive-dot",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={m.status !== 0}
                        onClick={() => {
                          setSelectedMoneybox(m);
                          setOpenDisable(true);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </TableContainer>
      </InfiniteScroll>
      {loading ? (
        <div className="loading-div">
          <LoadAnimation />
        </div>
      ) : (
        moneyboxes.results?.length <= 0 && (
          <div className="empty-div">
            <EmptyAnimation />
            <Typography component="p" variant="body2">
              No se encontraron alcancías para los filtros seleccionados
            </Typography>
          </div>
        )
      )}
      <DisableDialog
        open={openDisable}
        setOpen={setOpenDisable}
        message="¿Deseas desactivar esta alcancía? No podrás volver a activarla."
        deleteAction={() => selectedMoneybox && disableAction(selectedMoneybox.id)}
        deleteActionMessage="Si, desactivar"
      />
    </div>
  );
}

export default MoneyboxesList;
