import { BASE_URL } from './config';

export const endpoints = {
  authLogin: `${BASE_URL}/auth/authenticate/boffice`,
  authChangePassword: `${BASE_URL}/auth/password`,
  authResetPassword: `${BASE_URL}/auth/password/reset`,
  authVerifyEmail: `${BASE_URL}/auth/manual/verification`,
  getMoneyboxes: `${BASE_URL}/moneybox`,
  disableMoneybox: `${BASE_URL}/moneybox`,
  getActivemoneyboxes: `${BASE_URL}/moneybox/?status=0`,
  getMoneyboxesTotals: `${BASE_URL}/moneybox/totals`,
  getTransactions: `${BASE_URL}/transactions`,
  issuers: `${BASE_URL}/issuers`,
  getSupportedIssuers: `${BASE_URL}/issuers/supported`,
};
