import { Dispatch } from "redux";
import { transactionsService } from "../../services/transactions.service";
import {
  FetchTransactionsRequest,
  Order,
  TransactionsFilters,
  TransactionsResponse,
  SortTransactionValues,
} from "../config/types";
import {
  FetchTransactionsTypes,
  LOADING_TRANSACTIONS,
  ADD_TRANSACTIONS,
  TRANSACTIONS_ERROR,
  TRANSACTIONS_SORT,
  TransactionsSort,
} from "../config/ActionTypes";

//#region Fetch transactions

export const fetchTransactions = ({
  page,
  limit,
  reset,
  filters,
}: FetchTransactionsRequest) => (
  dispatch: Dispatch<FetchTransactionsTypes>
) => {
  dispatch(loadingTransactions(filters, reset));

  return transactionsService
    .fetchTransactions({ page, limit, reset, filters })
    .then(
      (response) => {
        dispatch(addTransactions(response!));
      },
      (error) => {
        dispatch(transactionsError(error));
      }
    );
};

const loadingTransactions = (
  filters: TransactionsFilters,
  reset: boolean
): FetchTransactionsTypes => ({
  type: LOADING_TRANSACTIONS,
  filters,
  reset,
});

const addTransactions = (
  transactionsResponse: TransactionsResponse
): FetchTransactionsTypes => ({
  type: ADD_TRANSACTIONS,
  transactionsResponse,
});

const transactionsError = (error: string): FetchTransactionsTypes => ({
  type: TRANSACTIONS_ERROR,
  error,
});

//#endregion

//#region Sort transactions
export const sortTransactions = (
  sortBy: keyof SortTransactionValues,
  order: Order
): TransactionsSort => {
  return {
    type: TRANSACTIONS_SORT,
    sortBy,
    order,
  };
};
//#endregion
