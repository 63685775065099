import Button from '@material-ui/core/Button';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CustomSnackbar } from '../../components/common/CustomSnackbar';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../helpers/schemas';
import { verifyEmail } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { RootState } from '../../store/config/types';

interface Values {
  email: string;
}

const mapStateToProps = (state: RootState) => ({ auth: state.auth });

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  verifyEmail: (email: string) => dispatch(verifyEmail(email)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function EmailVerification({ auth, verifyEmail }: PropsFromRedux) {
  const [verifyingEmail, setVerifyingEmail] = useState(false);

  const submit = (values: Values) => {
    setVerifyingEmail(true);
    verifyEmail(values.email);
  };

  const closeSnack = () => {
    setVerifyingEmail(false);
  };

  return (
    <>
      <Formik initialValues={{ email: '' }} validationSchema={schemas.EmailSchema} onSubmit={submit}>
        <Form className="mail-verification">
          <Field name="email" component={FormTextField} type="text" placeholder="Email" />
          <Button color="secondary" variant="contained" type="submit">
            Verificar
          </Button>
        </Form>
      </Formik>
      <CustomSnackbar
        open={verifyingEmail && (auth.verifyEmailSuccess || auth.verifyEmailErrorMessage !== null)}
        message={
          auth.verifyEmailSuccess
            ? 'Se verificó el mail correctamente'
            : 'Ocurrió un error al verificar el mail'
        }
        handleClose={closeSnack}
        type={auth.verifyEmailSuccess ? 0 : 1}
      />
    </>
  );
}

export default connector(EmailVerification);
