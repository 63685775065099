import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { authReducer } from "../reducers/auth";
import { moneyboxesReducer } from "../reducers/moneyboxes";
import { issuersReducer } from "../reducers/issuers";
import { transactionsReducer } from "../reducers/transactions";
import { AppActions } from "./ActionTypes";

export const rootReducer = combineReducers({
  auth: authReducer,
  moneyboxes: moneyboxesReducer,
  issuers: issuersReducer,
  transactions: transactionsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>));
