import {
  IssuersDataTypes,
  LOADING_ISSUERS,
  ADD_ISSUERS,
  ISSUERS_ERROR,
  CREATING_ISSUER,
  ADD_ISSUER,
  CREATE_ISSUER_ERROR,
  DELETING_ISSUER,
  DELETE_ISSUER,
  DELETE_ISSUER_ERROR,
  LOADING_SUPPORTED_ISSUERS,
  ADD_SUPPORTED_ISSUERS,
  SUPPORTED_ISSUERS_ERROR,
} from "../config/ActionTypes";
import { IssuersState } from "../config/types";

const initialState: IssuersState = {
  loadingIssuers: false,
  issuers: null,
  issuersErrorMessage: null,
  creatingIssuer: false,
  createIssuerSuccess: false,
  createIssuerErrorMessage: null,
  deletingIssuer: false,
  deleteIssuerSuccess: false,
  deleteIssuerErrorMessage: null,
  loadingSupportedIssuers: false,
  supportedIssuers: null,
  supportedIssuersErrorMessage: null,
};

export function issuersReducer(state = initialState, action: IssuersDataTypes) {
  switch (action.type) {
    case LOADING_ISSUERS:
      return {
        ...state,
        loadingIssuers: true,
        issuers: null,
        issuersErrorMessage: null,
      };

    case ADD_ISSUERS:
      return {
        ...state,
        loadingIssuers: false,
        issuers: action.issuers,
        issuersErrorMessage: null,
      };

    case ISSUERS_ERROR:
      return {
        ...state,
        loadingIssuers: false,
        issuers: null,
        issuersErrorMessage: action.error,
      };

    case CREATING_ISSUER:
      return {
        ...state,
        creatingIssuer: true,
        createIssuerSuccess: false,
        createIssuerErrorMessage: null,
      };

    case ADD_ISSUER:
      let addedIssuers = state.issuers;
      addedIssuers?.push(action.issuer);

      return {
        ...state,
        creatingIssuer: false,
        createIssuerSuccess: true,
        issuers: addedIssuers,
        createIssuerErrorMessage: null,
      };

    case CREATE_ISSUER_ERROR:
      return {
        ...state,
        creatingIssuer: false,
        createIssuerSuccess: false,
        createIssuerErrorMessage: action.error,
      };

    case DELETING_ISSUER:
      return {
        ...state,
        deletingIssuer: true,
        deleteIssuerSuccess: false,
        deleteIssuerErrorMessage: null,
      };

    case DELETE_ISSUER:
      //TODO: REMOVE
      // let existingLinks = state.selectedMoneybox?.links;

      // existingLinks = existingLinks?.filter((l) => {
      //   if (l.code !== action.linkId) {
      //     return l;
      //   }
      //   return null;
      // });
      const newIssuers = state.issuers?.filter((i) => i.issuerId !== action.issuerId);
      return {
        ...state,
        deletingIssuer: false,
        deleteIssuerSuccess: true,
        deleteIssuerErrorMessage: null,
        issuers: newIssuers,
      };

    case DELETE_ISSUER_ERROR:
      return {
        ...state,
        deletingIssuer: false,
        deleteIssuerSuccess: false,
        deleteIssuerErrorMessage: action.error,
      };

    case LOADING_SUPPORTED_ISSUERS:
      return {
        ...state,
        loadingSupportedIssuers: true,
        supportedIssuers: null,
        supportedIssuersErrorMessage: null,
      };

    case ADD_SUPPORTED_ISSUERS:
      return {
        ...state,
        loadingSupportedIssuers: false,
        supportedIssuers: action.issuers,
        supportedIssuersErrorMessage: null,
      };

    case SUPPORTED_ISSUERS_ERROR:
      return {
        ...state,
        loadingSupportedIssuers: false,
        supportedIssuers: null,
        supportedIssuersErrorMessage: action.error,
      };

    default:
      return state;
  }
}
