import { Dispatch } from "redux";
import { issuersService } from "../../services/issuers.service";
import {
  LOADING_ISSUERS,
  ADD_ISSUERS,
  ISSUERS_ERROR,
  FetchIssuersTypes,
  CREATING_ISSUER,
  ADD_ISSUER,
  CREATE_ISSUER_ERROR,
  CreateIssuerTypes,
  DELETING_ISSUER,
  DELETE_ISSUER,
  DELETE_ISSUER_ERROR,
  DeleteIssuerTypes,
  LOADING_SUPPORTED_ISSUERS,
  ADD_SUPPORTED_ISSUERS,
  SUPPORTED_ISSUERS_ERROR,
  FetchSupportedIssuersTypes,
} from "../config/ActionTypes";
import { CreateIssuerRequest, DeleteIssuerRequest, Issuer, SupportedIssuer } from "../config/types";

//#region Fetch issuers

export const fetchIssuers = () => (dispatch: Dispatch<FetchIssuersTypes>) => {
  dispatch(loadingIssuers());

  return issuersService.fetchIssuers().then(
    (response) => {
      dispatch(addIssuers(response!));
    },
    (error) => {
      dispatch(issuersError(error));
    }
  );
};

const loadingIssuers = (): FetchIssuersTypes => ({
  type: LOADING_ISSUERS,
});

const addIssuers = (issuers: Issuer[]): FetchIssuersTypes => ({
  type: ADD_ISSUERS,
  issuers,
});

const issuersError = (error: string): FetchIssuersTypes => ({
  type: ISSUERS_ERROR,
  error,
});

//#endregion

//#region Create issuer

export const createIssuer = ({ issuerId, commerceNumber, commerceTerminalNumber }: CreateIssuerRequest) => (
  dispatch: Dispatch<CreateIssuerTypes>
) => {
  dispatch(creatingIssuer());

  const createIssuerRequest: CreateIssuerRequest = {
    issuerId,
    commerceNumber,
    commerceTerminalNumber,
  };

  return issuersService.createIssuer(createIssuerRequest).then(
    (response) => {
      dispatch(addIssuer(response!));
    },
    (error) => {
      dispatch(createIssuerError(error));
    }
  );
};

const creatingIssuer = (): CreateIssuerTypes => ({
  type: CREATING_ISSUER,
});

const addIssuer = (issuer: Issuer): CreateIssuerTypes => ({
  type: ADD_ISSUER,
  issuer,
});

const createIssuerError = (error: string): CreateIssuerTypes => ({
  type: CREATE_ISSUER_ERROR,
  error,
});

//#endregion

//#region Delete issuer

export const deleteIssuer = ({ issuerId }: DeleteIssuerRequest) => (dispatch: Dispatch<DeleteIssuerTypes>) => {
  dispatch(deletingIssuer());

  return issuersService.deleteIssuer(issuerId).then(
    (response) => {
      dispatch(removeIssuer(issuerId));
    },
    (error) => {
      dispatch(deleteIssuerError(error));
    }
  );
};

const deletingIssuer = (): DeleteIssuerTypes => ({
  type: DELETING_ISSUER,
});

const removeIssuer = (issuerId: number): DeleteIssuerTypes => ({
  type: DELETE_ISSUER,
  issuerId,
});

const deleteIssuerError = (error: string): DeleteIssuerTypes => ({
  type: DELETE_ISSUER_ERROR,
  error,
});

//#endregion

//#region Fetch supported issuers

export const fetchSupportedIssuers = () => (dispatch: Dispatch<FetchSupportedIssuersTypes>) => {
  dispatch(loadingSupportedIssuers());

  return issuersService.fetchSupportedIssuers().then(
    (response) => {
      dispatch(addSupportedIssuers(response!));
    },
    (error) => {
      dispatch(supportedIssuersError(error));
    }
  );
};

const loadingSupportedIssuers = (): FetchSupportedIssuersTypes => ({
  type: LOADING_SUPPORTED_ISSUERS,
});

const addSupportedIssuers = (issuers: SupportedIssuer[]): FetchSupportedIssuersTypes => ({
  type: ADD_SUPPORTED_ISSUERS,
  issuers,
});

const supportedIssuersError = (error: string): FetchSupportedIssuersTypes => ({
  type: SUPPORTED_ISSUERS_ERROR,
  error,
});

//#endregion
