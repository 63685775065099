import React from "react";
import { connect, ConnectedProps } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik, Form, Field } from "formik";
import { FormTextField } from "../../components/forms/FormTextField";
import { changePassword } from "../../store/action_creators/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import { RootState, ChangePasswordRequest } from "../../store/config/types";
import { AppActions } from "../../store/config/ActionTypes";
import schemas from "../../helpers/schemas";
import Snackbar from "@material-ui/core/Snackbar";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  changePassword: (request: ChangePasswordRequest) => dispatch(changePassword(request)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function UserProfile({ auth, changePassword }: PropsFromRedux) {
  return (
    <div className="user-profile">
      <div className="title">
        <Typography variant="h5" component="h2">
          Cambiar contraseña
        </Typography>
      </div>
      <Divider light />
      <Formik
        initialValues={{ oldPassword: "", newPassword: "", newPasswordConfirm: "" }}
        onSubmit={(values) => {
          changePassword({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          });
        }}
        validationSchema={schemas.ChangePasswordSchema}
      >
        {(props) => (
          <Form className="change-password-form">
            <Field
              name="oldPassword"
              id="oldPassword"
              value={props.values.oldPassword}
              component={FormTextField}
              type="password"
              placeholder="Contraseña actual"
              className="field"
            />
            <Field
              name="newPassword"
              id="newPassword"
              value={props.values.newPassword}
              component={FormTextField}
              type="password"
              placeholder="Nueva contraseña"
              className="field"
            />
            <Field
              name="newPasswordConfirm"
              id="newPasswordConfirm"
              value={props.values.newPasswordConfirm}
              component={FormTextField}
              type="password"
              placeholder="Repetir nueva contraseña"
              className="field"
            />
            <div className="actions-row">
              <div className="change-password-action">
                {!auth.changingPassword ? (
                  <Button color="secondary" variant="contained" type="submit">
                    Cambiar contraseña
                  </Button>
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={(auth.changePasswordSuccess || auth.changePasswordErrorMessage !== null) && !auth.changingPassword}
        autoHideDuration={4000}
      >
        <MuiAlert elevation={6} variant="filled" severity={auth.resetPasswordSuccess ? "success" : "error"}>
          {auth.resetPasswordSuccess ? "Contraseña actualizada." : "Ocurrió un error al cambiar tu contraseña."}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default connector(UserProfile);
