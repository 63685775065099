import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, FetchMoneyboxesRequest, Order, SortMoneyboxValues } from "../../store/config/types";
import { AppActions } from "../../store/config/ActionTypes";
import {
  fetchMoneyboxes,
  disableMoneybox,
  fetchMoneyboxesTotals,
  sortMoneyboxes,
} from "../../store/action_creators/moneyboxes.actions";
import { MoneyboxesList } from "../../components/moneyboxes/MoneyboxesList";
import { TransactionsList } from "../../components/transactions/TransactionsList";
import LoadingAnimation from "../../components/common/animations/LoadingAnimation";

const mapStateToProps = (state: RootState) => ({
  moneyboxes: state.moneyboxes,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => {
  return {
    fetchMoneyboxes: ({ page, limit, reset, filters, sort }: FetchMoneyboxesRequest) =>
      dispatch(
        fetchMoneyboxes({
          page,
          limit,
          reset,
          filters,
          sort,
        }),
      ),
    disableMoneybox: (id: string) => dispatch(disableMoneybox(id)),
    fetchMoneyboxesTotals: () => dispatch(fetchMoneyboxesTotals()),
    sortMoneyboxes: (sortBy: keyof SortMoneyboxValues, order: Order) =>
      dispatch(sortMoneyboxes(sortBy, order)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Dashboard({
  moneyboxes,
  fetchMoneyboxes,
  disableMoneybox,
  fetchMoneyboxesTotals,
  sortMoneyboxes,
}: PropsFromRedux) {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const fetchLimit = 15;

  useEffect(() => {
    if (!moneyboxes.moneyboxes && !moneyboxes.fetchingMoneyboxes && !moneyboxes.fetchMoneyboxesErrorMessage) {
      fetchMoneyboxes({
        page: 1,
        limit: fetchLimit,
        reset: false,
        filters: moneyboxes.filters,
        sort: moneyboxes.sort,
      });
    }
  }, [
    moneyboxes.moneyboxes,
    moneyboxes.fetchingMoneyboxes,
    moneyboxes.fetchMoneyboxesErrorMessage,
    moneyboxes.filters,
    moneyboxes.sort,
    fetchMoneyboxes,
  ]);

  useEffect(() => {
    if (!moneyboxes.totals && !moneyboxes.fetchingTotals && !moneyboxes.fetchTotalsErrors) {
      fetchMoneyboxesTotals();
    }
  }, [moneyboxes.totals, moneyboxes.fetchingTotals, moneyboxes.fetchTotalsErrors, fetchMoneyboxesTotals]);

  return (
    <div className="dashboard">
      <div className="dashboard-heading">
        <div className="total-moneyboxes">
          <p className="total-speech">Alcancías activas</p>
          {moneyboxes.totals ? (
            <span className="total">{moneyboxes.totals.totalActiveMoneyboxes}</span>
          ) : (
            <LoadingAnimation />
          )}
        </div>
        <div className="total-raised">
          <p className="total-speech">Total recaudado hasta el momento</p>
          <div className="total-amounts">
            {moneyboxes.totals ? (
              <>
                <span className="currency">$</span>
                <span className="total">{moneyboxes.totals.raisedAmount}</span>
                <span className="currency">US$</span>
                <span className="total">{moneyboxes.totals.raisedAmountDollars}</span>
              </>
            ) : (
              <LoadingAnimation />
            )}
          </div>
        </div>
      </div>
      <div className="alert">
        <Alert className="mobile-only" severity="warning">
          Accede desde una computadora para ver las tablas completas.
        </Alert>
      </div>
      <div>
        <AppBar position="static" elevation={0} color="transparent">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            classes={{
              root: "dashboard-tabs",
              indicator: "dashboard-tabs-indicator",
            }}
          >
            <Tab label="Alcancías" classes={{ selected: "dashboard-tab" }} />
            <Tab label="Donaciones" classes={{ selected: "dashboard-tab" }} />
          </Tabs>
        </AppBar>
        <div hidden={value !== 0} tabIndex={0}>
          {moneyboxes.moneyboxes && (
            <MoneyboxesList
              moneyboxes={moneyboxes.moneyboxes}
              disableAction={disableMoneybox}
              fetchMoneyboxes={fetchMoneyboxes}
              fetchLimit={fetchLimit}
              sortMoneyboxes={sortMoneyboxes}
              filters={moneyboxes.filters}
              sort={moneyboxes.sort}
              loading={moneyboxes.fetchingMoneyboxes}
            />
          )}
        </div>
        <div hidden={value !== 1} tabIndex={1}>
          <TransactionsList />
        </div>
      </div>
    </div>
  );
}

export default connector(Dashboard);
